export function calculateWinner (squares) {
    const combinations = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6]
    ];

    for (let combination of combinations) {
        const [a, b, c] = combination;
        if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
            return { player: squares[a], winningCombination: [a, b, c] }
        }
    }
}

const minimax = (board, depth, maximizingPlayer) => {
    const winner = calculateWinner(board);

    if (winner && winner.player === "X") {
        return depth - 10; // Player X wins, subtract depth to favor quicker wins
    }
    if (winner && winner.player === "O") {
        return 10 - depth; // Player O wins, subtract depth to favor quicker wins
    }
    if (board.every(cell => cell !== null)) {
        return 0; // It's a draw
    }

    let newBoard;

    if (maximizingPlayer) {
        let maxScore = -Infinity;

        for (let i = 0; i < board.length; i++) {
            if (board[i] === null) {
                newBoard = [...board];
                newBoard[i] = "O"; // Try a possible move for O

                let score = minimax(newBoard, depth + 1, false);
                maxScore = Math.max(maxScore, score);
            }
        }
        return maxScore;
    } else {
        let minScore = Infinity;

        for (let i = 0; i < board.length; i++) {
            if (board[i] === null) {
                newBoard = [...board];
                newBoard[i] = "X"; // Try a possible move for X

                let score = minimax(newBoard, depth + 1, true);
                minScore = Math.min(minScore, score);
            }
        }
        return minScore;
    }
};

export function findBestMove(board) {
    let bestScore = -Infinity;
    let bestMove;
    let newBoard; 

    for (let i = 0; i < board.length; i++) {
        if (board[i] === null) {
            newBoard = [...board];
            newBoard[i] = "O"; // Try a possible move for O
            const score = minimax(newBoard, 0, false);

            if (score > bestScore) {
                bestScore = score;
                bestMove = i;
            }
        }
    }

    return bestMove;
};