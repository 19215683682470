import classes from "./GameModeActions.module.css";

const GameModeActions = props => {
    return <div className={classes["mode-action"]}>
        <button onClick={() => props.onModeChange("easy")} disabled={props.gameDifficulty === "easy" ? true : false}>Easy</button>
        <button onClick={() => props.onModeChange("intermediate")} disabled={props.gameDifficulty === "intermediate" ? true : false}>Intermediate</button>
        <button onClick={() => props.onModeChange("expert")} disabled={props.gameDifficulty === "expert" ? true : false}>Expert</button>
    </div>
}

export default GameModeActions;