import { useEffect, useReducer } from "react";

import { calculateWinner, findBestMove } from "../utils/utils";

import Board from "./Board";
import classes from "./Game.module.css";
import GameModeActions from "./GameModeActions";

// Default state
const initialGameState = {
    gameHistory: [{ squares: Array(9).fill(null) }],
    gameDifficulty: "intermediate",
    xIsNext: true,
    gameOver: false,
    winner: { player: null, winningCombination: [] }
};

// Game reducer
const gameStateReducer = (state, action) => {
    let newState;
    switch (action.type) {
        case "MOVE":
            const squares = [...state.gameHistory[state.gameHistory.length - 1].squares];
            squares[action.i] = state.xIsNext ? "X" : "O";
            newState = { ...state };
            newState.gameHistory.push({ squares });
            const winner = calculateWinner(newState.gameHistory[state.gameHistory.length - 1].squares);
            if (winner) {
                newState.winner = winner;
                newState.gameOver = true;
            }
            if (newState.gameHistory[state.gameHistory.length - 1].squares.every(square => square !== null)) {
                newState.gameOver = true;
                return newState;
            };
            newState.xIsNext = !state.xIsNext;
            return newState;

        case "UNDO":
            newState = { ...state };
            newState.gameHistory.pop();
            newState.xIsNext = !state.xIsNext;
            return newState;

        case "RESET":
            newState = {
                gameHistory: [{ squares: Array(9).fill(null) }],
                gameDifficulty: state.gameDifficulty,
                xIsNext: true,
                gameOver: false,
                winner: { player: null, winningCombination: [] }
            };
            return newState;

        case "CHANGE_DIFFICULTY":
            newState = { ...state };
            newState.gameDifficulty = action.difficulty;
            return newState;

        default:
            return state;
    };
}

const Game = () => {
    const [gameState, dispatchGameState] = useReducer(gameStateReducer, initialGameState);

    // Extract values from state and updating current game
    const { gameHistory, xIsNext, winner, gameOver } = gameState;
    let currentGame = gameHistory[gameHistory.length - 1].squares;

    // Manage computer's turn
    useEffect(() => {
        if (!xIsNext && !winner.player) {
            let bestMove = findBestMove(currentGame);
            let randomMove = Math.floor(Math.random() * 9);

            while (currentGame[randomMove]) {
                randomMove = Math.floor(Math.random() * 9);
            }

            let randomNumber;
            let move;

            switch (gameState.gameDifficulty) {
                case "easy":
                    randomNumber = Math.ceil(Math.random() * 20);
                    if (randomNumber <= 11) {
                        move = bestMove;
                    } else {
                        move = randomMove;
                    }
                    break;
                case "intermediate":
                    randomNumber = Math.ceil(Math.random() * 20);
                    if (randomNumber <= 16) {
                        move = bestMove;
                    } else {
                        move = randomMove;
                    }
                    break;
                case "expert":
                    randomNumber = Math.ceil(Math.random() * 20);
                    if (randomNumber <= 19) {
                        move = bestMove;
                    } else {
                        move = randomMove;
                    }
                    break;
            }

            const delay = setTimeout(() => {
                dispatchGameState({ type: "MOVE", i: move });
            }, Math.floor(Math.random() * 1500) + 500);

            return () => clearTimeout(delay);
        }
    }, [xIsNext, winner, currentGame]);

    // Player's move
    const handleClick = i => {
        if (currentGame[i] || winner.player || !xIsNext) {
            return;
        }
        dispatchGameState({ type: "MOVE", i });
    };

    // Reset game
    const handleReset = () => {
        dispatchGameState({ type: "RESET" });
    }

    // Undo last move
    const handleUndo = () => {
        if (gameHistory.length === 1) {
            return;
        }
        dispatchGameState({ type: "UNDO" });
    }

    const handleChangeMode = newDifficulty => {
        dispatchGameState({ type: "CHANGE_DIFFICULTY", difficulty: newDifficulty });
    }

    const status = winner.player ? `Winner: ${winner.player}` : gameOver ? "Draw!" : `${xIsNext ? "It's your turn!" : ""}`
    const actionClasses = `${classes["game-actions"]} ${winner.player ? classes["game-actions-won"] : gameOver ? classes["game-actions-over"] : ""}`;

    return <div className={classes.game}>
        <div className={actionClasses}>
            <p className={classes.status}>{status}</p>
            {winner.player || gameOver ?
                <button className={classes.action} onClick={handleReset}>Reset</button> :
                <button className={classes.action} onClick={handleUndo}>Undo</button>
            }
        </div>
        <Board
            xIsNext={xIsNext}
            winningCombination={winner.winningCombination}
            gameOver={gameOver}
            currentGame={currentGame}
            handleClick={handleClick}
        />
        <GameModeActions onModeChange={handleChangeMode} gameDifficulty={gameState.gameDifficulty} />
    </div>
}

export default Game;