import Block from "./Block";
import classes from "./Board.module.css";

const Board = props => {
    const createBlock = i => {
        return <Block
            xIsNext={props.xIsNext}
            winning={props.winningCombination && props.winningCombination.includes(i)}
            gameOver={props.gameOver}
            handleClick={() => props.handleClick(i)}
            value={props.currentGame[i]}
        />
    }

    return <div className={classes.board}>
        <div className={classes["board-row"]}>
            {createBlock(0)}
            {createBlock(1)}
            {createBlock(2)}
        </div>
        <div className={classes["board-row"]}>
            {createBlock(3)}
            {createBlock(4)}
            {createBlock(5)}
        </div>
        <div className={classes["board-row"]}>
            {createBlock(6)}
            {createBlock(7)}
            {createBlock(8)}
        </div>
    </div>
}

export default Board;