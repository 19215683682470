import classes from "./Block.module.css";

const Block = props => {
    const blockClasses = `${classes["board-box"]} ${props.winning ? classes["board-box-winning"] : ""} ${props.value || props.gameOver ? classes["board-box-full"] : !props.xIsNext ? "" : classes["board-box-active"]}`;

    return <div
        onClick={props.handleClick}
        className={blockClasses}
    >
        {props.value}
    </div>;
}

export default Block;